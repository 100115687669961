import authSlice from './auth';
import pageSlice from './page';
import MODEL_NAME from 'typings/models/_model.enum';
import SliceFactory from './SliceFactory';
import serviceOrderedSlice from './serviceOrdered';
import taskSlice from './task';
import notificationSlice from './notification';
import { Invoice, InvoiceState } from 'typings/models/invoice';

const slices = {
  [MODEL_NAME.region]: SliceFactory.createModelSlice<MODEL_NAME.region, City, CityState>(MODEL_NAME.region),
  [MODEL_NAME.shop]: SliceFactory.createModelSlice<MODEL_NAME.shop, Shop, ShopState>(MODEL_NAME.shop),
  [MODEL_NAME.enterprise]: SliceFactory.createModelSlice<MODEL_NAME.enterprise, Enterprise, EnterpriseState>(MODEL_NAME.enterprise),
  [MODEL_NAME.platformOperator]: SliceFactory.createModelSlice<MODEL_NAME.platformOperator, PlatformOperator, PlatformOperatorState>(
    MODEL_NAME.platformOperator
  ),
  [MODEL_NAME.brand]: SliceFactory.createModelSlice<MODEL_NAME.brand, Brand, BrandState>(MODEL_NAME.brand),
  [MODEL_NAME.userPlatformOperator]: SliceFactory.createModelSlice<
    MODEL_NAME.userPlatformOperator,
    UserPlatformOperator,
    UserPlatformOperatorState
  >(MODEL_NAME.userPlatformOperator),
  [MODEL_NAME.userEnterprise]: SliceFactory.createModelSlice<MODEL_NAME.userEnterprise, UserEnterprise, UserEnterpriseState>(
    MODEL_NAME.userEnterprise
  ),
  [MODEL_NAME.legalEntity]: SliceFactory.createModelSlice<MODEL_NAME.legalEntity, LegalEntity, LegalEntityState>(MODEL_NAME.legalEntity),
  [MODEL_NAME.enterpriseDepartment]: SliceFactory.createModelSlice<
    MODEL_NAME.enterpriseDepartment,
    EnterpriseDepartment,
    EnterpriseDepartmentState
  >(MODEL_NAME.enterpriseDepartment),

  [MODEL_NAME.servicePackage]: SliceFactory.createModelSlice<MODEL_NAME.servicePackage, ServicePackage, ServicePackageState>(MODEL_NAME.servicePackage),
  [MODEL_NAME.serviceCategoryGroup]: SliceFactory.createModelSlice<
    MODEL_NAME.serviceCategoryGroup,
    ServiceCategoryGroup,
    ServiceCategoryGroupState
  >(MODEL_NAME.serviceCategoryGroup),
  [MODEL_NAME.serviceCategory]: SliceFactory.createModelSlice<MODEL_NAME.serviceCategory, ServiceCategory, ServiceCategoryState>(
    MODEL_NAME.serviceCategory
  ),
  [MODEL_NAME.service]: SliceFactory.createModelSlice<MODEL_NAME.service, BusinessService, BusinessServiceState>(MODEL_NAME.service),

  [MODEL_NAME.serviceProvider]: SliceFactory.createModelSlice<MODEL_NAME.serviceProvider, ServiceProvider, ServiceProviderState>(
    MODEL_NAME.serviceProvider
  ),
  [MODEL_NAME.userSP]: SliceFactory.createModelSlice<MODEL_NAME.userSP, UserSP, UserSPState>(MODEL_NAME.userSP),
  [MODEL_NAME.userSuperadmin]: SliceFactory.createModelSlice<MODEL_NAME.userSuperadmin, UserSuperadmin, UserSuperadminState>(
    MODEL_NAME.userSuperadmin
  ),
  [MODEL_NAME.widgetMatchSetting]: SliceFactory.createModelSlice<
    MODEL_NAME.widgetMatchSetting,
    WidgetMatchSetting,
    WidgetMatchSettingState
  >(MODEL_NAME.widgetMatchSetting),
  [MODEL_NAME.task]: taskSlice,
  [MODEL_NAME.orderComment]: SliceFactory.createModelSlice<MODEL_NAME.orderComment, Commentary, OrderCommentState>(MODEL_NAME.orderComment),
  [MODEL_NAME.orderProviderTransactionsData]: SliceFactory.createModelSlice<
    MODEL_NAME.orderProviderTransactionsData,
    OrderProviderTransactionsData,
    OrderProviderTransactionsDataState
  >(MODEL_NAME.orderProviderTransactionsData),
  [MODEL_NAME.orderReviewComment]: SliceFactory.createModelSlice<MODEL_NAME.orderReviewComment, Commentary, ReviewCommentState>(
    MODEL_NAME.orderReviewComment
  ),
  [MODEL_NAME.orderReview]: SliceFactory.createModelSlice<MODEL_NAME.orderReview, OrderReview, OrderReviewState>(MODEL_NAME.orderReview),
  [MODEL_NAME.orderReport]: SliceFactory.createModelSlice<MODEL_NAME.orderReport, OrderReport, OrderReportState>(MODEL_NAME.orderReport),
  [MODEL_NAME.availableTeamResources]: SliceFactory.createModelSlice<
    MODEL_NAME.availableTeamResources,
    AvailableTeamResources,
    AvailableTeamResourcesState
  >(MODEL_NAME.availableTeamResources),
  [MODEL_NAME.serviceOrdered]: serviceOrderedSlice,
  [MODEL_NAME.call]: SliceFactory.createModelSlice<MODEL_NAME.call, Call, CallState>(MODEL_NAME.call),
  [MODEL_NAME.invoice]: SliceFactory.createModelSlice<MODEL_NAME.invoice, Invoice, InvoiceState>(MODEL_NAME.invoice),
  [MODEL_NAME.orderHistory]: SliceFactory.createModelSlice<MODEL_NAME.orderHistory, OrderHistory, OrderHistoryState>(
    MODEL_NAME.orderHistory
  ),
  [MODEL_NAME.order]: SliceFactory.createModelSlice<MODEL_NAME.order, Order, OrderState>(MODEL_NAME.order),
  [MODEL_NAME.userBase]: SliceFactory.createModelSlice<MODEL_NAME.userBase, User.Base, User.UserBaseState>(MODEL_NAME.userBase),
  [MODEL_NAME.notification]: notificationSlice,
  [MODEL_NAME.orderType]: SliceFactory.createModelSlice<MODEL_NAME.orderType, OrderType, OrderTypeState>(MODEL_NAME.orderType),
  [MODEL_NAME.orderView]: SliceFactory.createModelSlice<MODEL_NAME.orderView, OrderView, OrderViewState>(MODEL_NAME.orderView),
  [MODEL_NAME.serverAction]: SliceFactory.createModelSlice<MODEL_NAME.serverAction, ServerAction, ServerActionState>(
    MODEL_NAME.serverAction
  ),
  [MODEL_NAME.country]: SliceFactory.createModelSlice<MODEL_NAME.country, Country, CountryState>(MODEL_NAME.country),
  [MODEL_NAME.priceClient]: SliceFactory.createModelSlice<MODEL_NAME.priceClient, Price.Client, PriceClientState>(MODEL_NAME.priceClient),
  [MODEL_NAME.priceServiceProvider]: SliceFactory.createModelSlice<MODEL_NAME.priceServiceProvider, Price.SP, PriceServiceProviderState>(
    MODEL_NAME.priceServiceProvider
  ),
  [MODEL_NAME.pricePlatformOperator]: SliceFactory.createModelSlice<
    MODEL_NAME.pricePlatformOperator,
    Price.PlatformOperator,
    PricePlatformOperatorState
  >(MODEL_NAME.pricePlatformOperator),
  [MODEL_NAME.priceTechnician]: SliceFactory.createModelSlice<MODEL_NAME.priceTechnician, Price.Technician, PriceTechnicianState>(
    MODEL_NAME.priceTechnician
  ),
  [MODEL_NAME.priceAny]: SliceFactory.createModelSlice<MODEL_NAME.priceAny, Price, PriceAnyState>(MODEL_NAME.priceAny),
  [MODEL_NAME.pricelistServiceProvider]: SliceFactory.createModelSlice<
    MODEL_NAME.pricelistServiceProvider,
    Pricelist.ServiceProvider,
    PricelistServiceProviderState
  >(MODEL_NAME.pricelistServiceProvider),
  [MODEL_NAME.pricelistPlatformOperator]: SliceFactory.createModelSlice<
    MODEL_NAME.pricelistPlatformOperator,
    Pricelist.PlatformOperator,
    PricelistPlatformOperatorState
  >(MODEL_NAME.pricelistPlatformOperator),
  [MODEL_NAME.technicianTeam]: SliceFactory.createModelSlice<MODEL_NAME.technicianTeam, TechnicianTeam, TechnicianTeamState>(
    MODEL_NAME.technicianTeam
  ),
  [MODEL_NAME.orderLabel]: SliceFactory.createModelSlice<MODEL_NAME.orderLabel, OrderLabel, OrderLabelState>(MODEL_NAME.orderLabel),
  [MODEL_NAME.contractSP]: SliceFactory.createModelSlice<MODEL_NAME.contractSP, ContractSP, ContractSPState>(MODEL_NAME.contractSP),
  [MODEL_NAME.contractPO]: SliceFactory.createModelSlice<MODEL_NAME.contractPO, ContractPO, ContractPOState>(MODEL_NAME.contractPO),
  [MODEL_NAME.fundsTransaction]: SliceFactory.createModelSlice<MODEL_NAME.fundsTransaction, FundsTransaction, FundsTransactionState>(
    MODEL_NAME.fundsTransaction
  ),
  [MODEL_NAME.customField]: SliceFactory.createModelSlice<MODEL_NAME.customField, CustomField, CustomFieldState>(MODEL_NAME.customField),
  [MODEL_NAME.promotionsConfig]: SliceFactory.createModelSlice<MODEL_NAME.promotionsConfig, PromotionsConfig, PromotionsConfigState>(
    MODEL_NAME.promotionsConfig
  ),
  [MODEL_NAME.dictionary]: SliceFactory.createModelSlice<MODEL_NAME.dictionary, Dictionary, DictionaryState>(MODEL_NAME.dictionary),
  [MODEL_NAME.orderDocument]: SliceFactory.createModelSlice<MODEL_NAME.orderDocument, OrderDocument, OrderDocumentState>(
    MODEL_NAME.orderDocument
  ),
  [MODEL_NAME.orderSession]: SliceFactory.createModelSlice<MODEL_NAME.orderSession, OrderContractSessionData, OrderContractSessionState>(
    MODEL_NAME.orderSession
  ),

  [MODEL_NAME.recommendationsConfig]: SliceFactory.createModelSlice<
    MODEL_NAME.recommendationsConfig,
    RecommendationsConfig,
    RecommendationsConfigState
  >(MODEL_NAME.recommendationsConfig),
  // Нестандартные
  auth: authSlice,
  page: pageSlice,
};

export default slices;
