import { CreateServicePackageRequestDTO, ServicePackageResponseDTO } from 'typings/dto/servicePackage';
import Mapper from './Mapper';

export default class ServicePackageMapper extends Mapper<ServicePackage, ServicePackageResponseDTO, CreateServicePackageRequestDTO> {
  constructor() {
    super({
      tenant: 'tenant',
      name: 'name',
      technicalName: 'technicalName',
      description: 'description'
    }, null);
  }
}
