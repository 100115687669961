import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { CreateShopRequestDTO, ShopResponseDTO } from 'typings/dto/shop';
import IShopActionsService from 'services/storageModelActions/shop/IShopActionsService';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';

export default class ShopActionsServiceImpl
  extends ModelActionsService<Shop, CreateShopRequestDTO, ShopResponseDTO>
  implements IShopActionsService
{
  /** @throws `BackendResponseError` */
  public getValuesFromOrderForFilter = (): Promise<void> => {
    const url = PATH_BACKEND.shop.orderFilter;
    return this.getAllForFilter(undefined, undefined, url);
  };
}
