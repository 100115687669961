import { ButtonProps, IconButton } from '@mui/material';
import { InfoIcon } from '../icons';


export default function InfoIconButton({ title, color, ...rest }: ButtonProps) {
  return (
    <IconButton title={title} {...rest}>
      <InfoIcon />
    </IconButton>
  );
}
