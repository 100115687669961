import DIContainer from 'services/DIContainer';
import { OrderCustomFieldRequestDTO, OrderCustomFieldResponseDTO } from 'typings/dto/order';
import DynamicFieldMapper from './DynamicFieldMapper';
import PhoneMapper from './PhoneMapper';
import { DYNAMIC_FIELD_RESPONSE_TYPE, DYNAMIC_FIELD_TYPE, DYNAMIC_FIELD_VALUE_TYPE } from 'typings/subEntities/dynamicField.enum';
import FunctionUtils from '../../Function';

export default class OrderCustomFieldMapper {
  constructor(private dynamicFieldMapper: DynamicFieldMapper, private phoneMapper: PhoneMapper) {}

  public responseDTOToModel = (dto: OrderCustomFieldResponseDTO, outerMappers: DIContainer.Mappers['models']): Order.CustomField => {
    let optionsSelectAndRadiogroup: EntityWithName[] = [];
    if (dto.settings.options) {
      optionsSelectAndRadiogroup = dto.settings.options.map((item) => {
        return { id: item.technicalName, name: item.name };
      });
    }
    const model: Order.CustomField = {
      id: dto.id,
      technicalName: dto.technicalName,
      type: dto.type,
      multiple: dto.multiple,
      required: dto.required,
      editable: dto.editable,
      name: dto.name,
      settings: {
        ...dto.settings,
        options: optionsSelectAndRadiogroup,
        labelFalse: dto.settings.labelFalse,
        labelTrue: dto.settings.labelTrue,
        defaultValue: dto.settings.defaultValue,
      },
      dynamicField: this.dynamicFieldMapper.responseDTOToModel(dto.dynamicFieldSettings, outerMappers),
      tenant: dto.tenant,
      category: dto.category ? dto.category : null,
      showInSessionByOrderType: dto.showInSessionByOrderType,
    };

    switch (model.dynamicField.type) {
      case DYNAMIC_FIELD_TYPE.date:
      case DYNAMIC_FIELD_TYPE.number:
      case DYNAMIC_FIELD_TYPE.visitDate:
      case DYNAMIC_FIELD_TYPE.switch:
      case DYNAMIC_FIELD_TYPE.file:
      case DYNAMIC_FIELD_TYPE.text:
      case DYNAMIC_FIELD_TYPE.checkbox:
      case DYNAMIC_FIELD_TYPE.textarea:
      case DYNAMIC_FIELD_TYPE.radioGroup: {
        break;
      }
      case DYNAMIC_FIELD_TYPE.select: {
        this.dynamicFieldMapperDTOToModelSelectField(model.dynamicField, outerMappers);
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(model.dynamicField);
      }
    }

    return model;
  };

  /**
   * Маппит значения динамического поля в нужную модель, если responseType не совпадает с valueType
   * @param dynamicField Поле для мутирования
   * @param outerMappers Мапперы
   */
  private dynamicFieldMapperDTOToModelSelectField(
    dynamicField: DynamicField.DynamicFieldSelectField,
    outerMappers: DIContainer.Mappers['models']
  ): void {
    switch (dynamicField.dataSource.responseType) {
      case DYNAMIC_FIELD_RESPONSE_TYPE.model:
      case DYNAMIC_FIELD_RESPONSE_TYPE.technicalNameWithName: {
        break;
      }
      case DYNAMIC_FIELD_RESPONSE_TYPE.idWithName: {
        switch (dynamicField.valueType) {
          case DYNAMIC_FIELD_VALUE_TYPE.enum:
          case DYNAMIC_FIELD_VALUE_TYPE.idWithName:
          case DYNAMIC_FIELD_VALUE_TYPE.string:
          case DYNAMIC_FIELD_VALUE_TYPE.technicalNameWithName: {
            break;
          }
          case DYNAMIC_FIELD_VALUE_TYPE.user: {
            dynamicField.values = dynamicField.values.map((i) => outerMappers.userBase.responseDTOToModelIdAndNamesOnly(i));
            break;
          }
          default: {
            FunctionUtils.exhaustiveCheck(dynamicField.valueType);
          }
        }
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(dynamicField.dataSource.responseType);
      }
    }
  }

  public modelToRequestDTO = (entity: Order.CustomField): OrderCustomFieldRequestDTO => {
    let optionsSelectAndRadiogroup: { technicalName: string; name: string }[] | undefined;
    if (entity.settings.options) {
      optionsSelectAndRadiogroup = entity.settings.options.map((item) => {
        return { technicalName: item.id, name: item.name };
      });
    }
    return {
      id: entity.id,
      technicalName: entity.technicalName,
      type: entity.type,
      multiple: entity.multiple,
      required: entity.required,
      name: entity.name,
      settings: entity.settings && {
        options: optionsSelectAndRadiogroup,
      },
      editable: entity.editable,
      category: entity.category,
      showInSessionByOrderType: entity.showInSessionByOrderType,
    };
  };
}
